// App.js
import React from 'react';
import GlobalStyles from './styles/GlobalStyles';
import Header from './components/Header';
import About from './components/About';
import Services from './components/Services';
import Location from './components/Location';
import Contact from './components/Contact';

const App = () => {
  return (
    <>
      <GlobalStyles />
      <Header />
      <About />
      <Services />
      <Location />
      <Contact />
    </>
  );
};

export default App;
