import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 4rem 2rem;
  background-color: #F8F9FA;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 2rem 0;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  color: #007BFF;
  font-weight: 700;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ServiceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
`;

const ServiceCard = styled.div`
  background: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin: 1rem;
  width: 220px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 180px;
  }
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const ServiceTitle = styled.h3`
  margin: 0;
  padding: 1rem;
  font-size: 1.1rem;
  color: #333;
  text-align: center;
`;

const Services = () => {
  const services = [
    { name: 'Braces', image: 'https://www.finefeather.in/wp-content/uploads/2023/06/022.png' },
    { name: 'Root Canal Treatment', image: 'https://www.finefeather.in/wp-content/uploads/2023/06/11-1.jpg' },
    { name: 'Dental Implant', image: 'https://www.finefeather.in/wp-content/uploads/2023/06/13-1.jpg' },
    { name: 'Denture', image: 'https://www.finefeather.in/wp-content/uploads/2023/06/02-1.jpg' },
    { name: 'Crowns & Bridges', image: 'https://www.finefeather.in/wp-content/uploads/2023/06/06-1.jpg' },
    { name: 'Full Mouth Reconstruction', image: 'https://www.finefeather.in/wp-content/uploads/2022/11/15-1.jpg' },
    { name: 'Children Dentistry', image: 'https://www.finefeather.in/wp-content/uploads/2022/11/16-1.jpg' },
    { name: 'Teeth Whitening', image: 'https://www.finefeather.in/wp-content/uploads/2022/11/17-1.jpg' },
    { name: 'Aligners', image: 'https://www.finefeather.in/wp-content/uploads/2022/11/aligd.jpg' },
    { name: 'Veneer and Componeers', image: 'https://www.finefeather.in/wp-content/uploads/2022/11/18-1.jpg' },
    { name: 'Fillings, Inlays and Onlays', image: 'https://www.finefeather.in/wp-content/uploads/2022/11/04-1.jpg' },
    { name: 'Teeth Straightening', image: 'https://www.finefeather.in/wp-content/uploads/2022/11/12-1.jpg' },
  ];

  return (
    <Section id="services">
      <Heading>Our Dental Services</Heading>
      <ServiceContainer>
        {services.map((service, index) => (
          <ServiceCard key={index}>
            <ServiceImage src={service.image} alt={service.name} />
            <ServiceTitle>{service.name}</ServiceTitle>
          </ServiceCard>
        ))}
      </ServiceContainer>
    </Section>
  );
};

export default Services;
