import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  background-color: #007BFF;
  color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem;
  }
`;

const TitleContainer = styled.div`
  text-align: center;

  @media (min-width: 769px) {
    text-align: left;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff;

  @media (min-width: 769px) {
    font-size: 2.5rem;
  }
`;

const Tagline = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: #f0f0f0;

  @media (min-width: 769px) {
    font-size: 1.2rem;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  @media (min-width: 769px) {
    flex-direction: row;
    gap: 2rem;
    margin-top: 0;
  }
`;

const NavItem = styled.a`
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;

  &:hover {
    color: #f8f9fa;
    text-decoration: underline;
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <TitleContainer>
        <Title>Vyas Dental</Title>
        <Tagline>Your smile, our priority</Tagline>
      </TitleContainer>
      <Nav>
        <NavItem href="#about">About</NavItem>
        <NavItem href="#services">Services</NavItem>
        <NavItem href="#contact">Contact</NavItem>
        <NavItem href="#location">Location</NavItem>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
