import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  display: flex;
  align-items: center;
  padding: 3rem 1rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 1.5rem 0;
  flex-direction: column;

  @media (min-width: 769px) {
    flex-direction: row;
    padding: 4rem 2rem;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  padding: 0 1rem;

  @media (min-width: 769px) {
    flex: 0 0 40%;
    padding-right: 2rem;
  }
`;

const Title = styled.h2`
  color: #007BFF;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;

  @media (min-width: 769px) {
    font-size: 2.5rem;
    text-align: left;
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #343A40;
  text-align: justify;

  @media (min-width: 769px) {
    font-size: 1.3rem;
  }
`;

const Image = styled.img`
  width: 80%;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  margin-top: 1rem;

  @media (min-width: 769px) {
    width: 60%;
    margin-top: 0;
  }
`;

const About = () => {
  return (
    <Section id="about">
      <TextContainer>
        <Title>About Our Clinic</Title>
        <Paragraph>
          At Vyas Dental, we are committed to providing you with the best dental care.
          Our experienced dentists use state-of-the-art technology to ensure a pain-free 
          and comfortable experience. Your smile is our priority, and we focus on 
          building lasting relationships with our patients.
        </Paragraph>
      </TextContainer>
      <Image src="https://avitahealth.in/wp-content/uploads/2023/07/4-2.png" alt="Dentist Clinic" />
    </Section>
  );
};

export default About;
