// styles/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Roboto', sans-serif; /* Use the custom font */
    background-color: #F8F9FA; /* Light gray background */
    color: #343A40; /* Dark gray text */
    line-height: 1.6;
    padding: 0 2rem;
  }

  h1, h2, h3, h4, h5 {
    color: #007BFF; /* Bright blue color */
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p {
    margin-bottom: 1rem;
    font-weight: 400;
  }

  a {
    text-decoration: none;
    color: #007BFF;
    transition: color 0.3s;

    &:hover {
      color: #0056b3; /* Darker shade on hover */
    }
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  button {
    cursor: pointer;
  }
`;

export default GlobalStyles;
