import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 4rem 2rem;
  background-color: #e3f2fd;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 2rem 0;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  color: #007BFF;
  font-weight: 700;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Address = styled.p`
  font-size: 1.3rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const MapFrame = styled.iframe`
  width: 100%;
  height: 300px;
  border: 0;
  border-radius: 10px;

  @media (max-width: 768px) {
    height: 250px;
  }
`;

const Location = () => {
  return (
    <Section id="location">
      <Heading>Our Location</Heading>
      <Address>123 Dental Street, Piplia Mandi, 458664</Address>
      <MapFrame
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434508986!2d144.96315741531955!3d-37.81410797975136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f161f17%3A0x5045675218ceed30!2sGoogleplex!5e0!3m2!1sen!2sus!4v1616786224273!5m2!1sen!2sus"
        title="Google Maps"
        allowFullScreen
      />
    </Section>
  );
};

export default Location;
