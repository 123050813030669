import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 2rem 1rem;
  background-color: #ffffff;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 1.5rem 0;

  @media (min-width: 769px) {
    padding: 4rem 2rem;
  }
`;

const Heading = styled.h2`
  font-size: 2rem;
  color: #007BFF;
  font-weight: 700;
  margin-bottom: 1rem;

  @media (min-width: 769px) {
    font-size: 2.5rem;
  }
`;

const ContactInfo = styled.p`
  font-size: 1rem;
  color: #333;
  margin: 0.5rem 0;

  @media (min-width: 769px) {
    font-size: 1.2rem;
  }
`;

const Highlight = styled.strong`
  color: #007BFF;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin: 1.5rem auto;

  @media (min-width: 769px) {
    max-width: 400px;
  }
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.75rem;
  font-size: 1rem;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:focus {
    border: 1px solid #007BFF;
  }
`;

const TextArea = styled.textarea`
  margin-bottom: 1rem;
  padding: 0.75rem;
  font-size: 1rem;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:focus {
    border: 1px solid #007BFF;
  }
`;

const Button = styled.button`
  padding: 0.75rem;
  font-size: 1rem;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Contact = () => {
  return (
    <Section id="contact">
      <Heading>Contact Us</Heading>
      <ContactInfo>
        Phone: <Highlight>7415689177</Highlight>
      </ContactInfo>
      <ContactInfo>
        Email: <Highlight>info@vyasdental.com</Highlight>
      </ContactInfo>
      <Form>
        <Input type="text" placeholder="Your Name" required />
        <Input type="email" placeholder="Your Email" required />
        <TextArea rows="4" placeholder="Your Message" required />
        <Button type="submit">Send Message</Button>
      </Form>
    </Section>
  );
};

export default Contact;
